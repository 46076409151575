<template>
  <div class="app">
    <AppHeader fixed>
      <SidebarToggler class="d-lg-none" display="md" mobile />
      <Logo></Logo>
      <SidebarToggler class="d-md-down-none" display="lg" />
      <TopNavBar/>
      <div style="width:250px; z-index: 9999;" class="d-none d-lg-block mr-4 ml-4">
        <v-select
          class="project-select"
          label="projectname"
          :options="this.projectData"
          placeholder="Select Project"
          :value="projectid"
          v-model="projectid"
        ></v-select>
      </div>
      <button @click="setActiveProject(projectid.projectname)" class="d-none d-lg-block btn btn-success">Go To Project</button>
      <b-navbar-nav class="ml-auto">
        <WtsDropdownAccnt/>
      </b-navbar-nav>
    </AppHeader>
    <div class="app-body">
      <AppSidebar fixed>
        <SidebarHeader/>
        <SidebarForm/>
        <SidebarNav :navItems="nav"></SidebarNav>
        <SidebarFooter/>
        <SidebarMinimizer/>
      </AppSidebar>
      <main class="main">
        <Breadcrumb :list="list"/>
        <div class="container-fluid">
          <flash-message autoHide variant="success"></flash-message>
        </div>
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </main>
    </div>
    <TheFooter>
      <!--footer-->
      <!-- <div class="">
        <span class="mr-1">Need Help?</span>
        <router-link :to="{ name: 'Support'}">Support</router-link>
      </div> -->
      <div class="ml-auto">
        <span class="mr-1">Powered by</span>
        <a href="https://rtnest.ca">RTNest Inc. &copy; {{CopyrightDate}}</a>
      </div>
    </TheFooter>
  </div>
</template>

<script>
import nav from '@/_wts'
import { Header as AppHeader, SidebarToggler, Sidebar as AppSidebar, SidebarFooter, SidebarForm, SidebarHeader, SidebarMinimizer, SidebarNav, Aside as AppAside, AsideToggler, Footer as TheFooter } from '@coreui/vue'

import Breadcrumb from './BreadCrumbCustom'
import TopNavBar from './TopNavBar'
import WtsDropdownAccnt from './WtsDropdownAccnt'
import Logo from './Logo'
export default {
  name: 'WtsContainer',
  components: {
    AsideToggler,
    AppHeader,
    AppSidebar,
    AppAside,
    TheFooter,
    Logo,
    Breadcrumb,
    WtsDropdownAccnt,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer,
    TopNavBar
  },
  data () {
    return {
      projectid: 0,
      project: 0,
      projectData: [],
      nav: nav.items,
      CopyrightDate:null
  
    }
  },
  methods: {
    setActiveProject(val) {
      this.$session.set('projectname', val)
      this.project = val.projectid;

      if(this.projectid.projectid){
        this.$router.push({ name: 'Project Manager Project', params: { id: this.projectid.projectid } })
      }
    }
  },
  mounted: function () {
    this.CopyrightDate = new Date().getFullYear();
    this.$http.get('/wts/get/projects')
    .then(response => {
      this.projectData = response.data.result;
    })
    .catch(error => {
    })
  },
  computed: {
    name () {
      return this.$route.name
    },
    list () {
      return this.$route.matched.filter((route) => route.name || route.meta.label )
    }
  },
}
</script>
